<template>
  <section v-if="advisors" class="py-32 md:py-48 lg:py-88">
    <div class="container">
      <slot name="header" />

      <div class="flex flex-wrap -mx-16 gap-y-16 md:gap-y-24 xl:gap-y-40">
        <article v-if="isAdvisor" class="px-16 w-full md:w-1/2 xl:w-1/3">
          <se-advisor-card :advisor="currentUser" />
        </article>

        <article v-for="advisor of advisors" :key="advisor.id" class="px-16 w-full md:w-1/2 xl:w-1/3">
          <se-advisor-card :advisor="advisor" />
        </article>
      </div>

      <slot name="footer" />
    </div>
  </section>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import { getGlobalProperties } from '~/shared/helpers/getGlobalProperties';
import { usersStore } from '~/store/users';
import SeAdvisorCard from '~/modules/advisor/components/advisor-card.vue';

const { $api } = getGlobalProperties();

const users = usersStore();
const { currentUser, isAdvisor } = storeToRefs(users);

const advisors = ref<any[]>([]);

const fetchAdvisors = async (onlineOnly: string = '') => {
  await $api.get('/user/advisors', {
    sort: 'rankings',
    order: 'desc',
    limit: 6,
    status: onlineOnly,
  })
    .then(async ({ data }: any) => {
      advisors.value = data.data;
      if (advisors.value.length === 0 && !!onlineOnly) {
        await fetchAdvisors();
      }

      // Not to show advisor twice when advisor is logged in and is in tops.
      advisors.value = advisors.value.filter((a) => a.id !== currentUser.value.id);
      // Not to show extra person on the page when advisor is logged in and is not in tops.
      if (advisors.value.length === 6 && isAdvisor.value) {
        advisors.value = advisors.value.slice(0, -1);
      }
    });
};

await fetchAdvisors('online');
</script>

<script lang="ts">
export default {
  name: 'SeAdvisorTopAdvisorsList',
};
</script>
